import { type Injector } from '@angular/core';
import { BaseWidget } from '@sds/widget-gridster';

export class CustomerLogoWidget extends BaseWidget<never> {
  static readonly id = 'customer-logo';
  static readonly publicFriendly = false;

  static factory(injector: Injector, metaLinkId: string, x: number, y: number, rows: number, cols: number) {
    return new CustomerLogoWidget(injector, metaLinkId, x, y, rows, cols);
  }

  constructor(injector: Injector, metaLinkId: string, x: number, y: number, rows: number, cols: number) {
    super(injector, CustomerLogoWidget.id, CustomerLogoWidget.publicFriendly, metaLinkId, x, y, rows, cols);
    this.resolveComponent(async () => {
      const module = await import('./customer-logo-widget/customer-logo-widget.component');
      return Object.values(module)[0];
    });
  }
}
